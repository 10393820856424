import React from 'react'
import LayoutDefault from "../layouts";
import ChangeLog from '../components/ChangeLog'
import * as classes from '../sass/style.module.scss'
import { L10nProvider } from '@logdoc/l10n';
import useLocale from '@logdoc/l10n';

const Hh = () => {
    return (
        <L10nProvider>
            <Home />
        </L10nProvider>
    )
}

export const Home = () => {
    const t = useLocale()
    return (
            <LayoutDefault>
                <section className={classes.sec}>
                    <h2 className={classes.secTitle}>
                        LogDoc
                    </h2>
                    <div className={classes.secInfo}>
                        <p>
                            {t('home.title')}
                        </p>
                        <p>
                            {t('home.description')}
                        </p>
                    </div>
                    <div className={classes.secDescription}>
                        <p>
                            {t('home.description.p2')} <a href="https://github.com/LogDoc-org/sdk" target="_blank">SDK</a> {t('home.description.p1')}
                        </p>
                        <p>{t('home.description.p3')} <a href="https://clickhouse.com/" target="_blank">ClickHouse</a> {t('home.description.p4')}</p>
                        <p>{t('home.description.p5')}</p>
                        <p>{t('home.description.p6')}</p>
                        <p>{t('home.description.p7')}</p>
                    </div>
                </section>
                <ChangeLog/>
            </LayoutDefault>
    );
};

export default Hh;

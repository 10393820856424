import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { Home } from '../'

const Hh = () => {
    return (
        <L10nProvider>
            <Home />
        </L10nProvider>
    )
}

export default Hh;

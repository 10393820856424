import * as classes from "../../sass/style.module.scss";
import React, { useState } from "react";
import { useFullLocale } from '@logdoc/l10n';
import AccordionItem from './accordionItem'
import log from './changes.json'
import logEn from './changesEN.json'

const ChangeLog = () => {

    const [t, _, locale] = useFullLocale()
    const [splitBy, setSplitBy] = useState(10)

    const showMore = () => {
        setSplitBy(splitBy+10)
    }

    const list = locale === 'en' ? logEn : log

    return (
        <section className={classes.sec}>
            <h2 className={classes.secTitle}>
                Change Log
            </h2>
            <div className={classes.accordion}>
                {
                    list.changes.slice(0, splitBy) .map((l) => (
                        <AccordionItem {...l} />
                    ))
                }
                {
                    splitBy < log.changes.length ? (
                        <button onClick={showMore} className={classes.accordionMore}>
                            {t('changelog.more')}
                        </button>
                    ) : null
                }
            </div>
        </section>
    )
}

export default ChangeLog
import React, { useState } from "react";
import cn from 'classnames'
import * as classes from "../../sass/style.module.scss";

const AccordionItem = ({ date, text }) => {
    const [isOpen, setOpen] = useState(false)
    const toggleOpen = () => {
        setOpen(!isOpen)
    }

    return (
        <div onClick={toggleOpen} className={cn(classes.accordionItem, {
            [classes.isOpen]: isOpen
        })}>
            <div className={classes.accordionDate}>
                {date}
            </div>
            <div className={classes.accordionDescription}>
                {text}
            </div>
            <button className={classes.accordionAction}/>
        </div>
    )
}

export default AccordionItem